import type { NextPage } from 'next';
import Head from 'next/head';

import { TrackingPortal } from '$src/modules/tracking-portal';

const TrackingPortalPage: NextPage = () => (
  <>
    <Head>
      <title>Track your delivery | Ingrid Tracking Portal</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=2" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=2" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=2" />
      <link rel="manifest" href="/site.webmanifest?v=2" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#5bbad5" />
      <link rel="shortcut icon" href="/favicon.ico?v=2" />
      <link rel="canonical" href="https://tracking.ingrid.com" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
      <meta
        name="description"
        content="Search & track your delivery for all carriers with Ingrid improved delivery experience"
      />
      <meta name="referrer" content="strict-origin-when-cross-origin" />
      <meta property="og:url" content="https://tracking.ingrid.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Track your delivery" />
      <meta
        property="og:description"
        content="Check your order status, change delivery option or rate delivery experience."
      />
      <meta property="og:image" content="https://cdn.ingrid.com/static/assets/ingrid-og-logo.png" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200px" />
      <meta property="og:image:height" content="630px" />
      <meta property="og:image:alt" content="Young blonde woman using her smartphone" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="tracking.ingrid.com" />
      <meta property="twitter:url" content="https://tracking.ingrid.com/" />
      <meta name="twitter:title" content="Ingrid Tracking Portal" />
      <meta
        name="twitter:description"
        content="Check your order status, change delivery option or rate delivery experience."
      />
      <meta
        name="twitter:image"
        content="https://cdn.ingrid.com/static/assets/ingrid-og-logo.png"
      />
      <meta name="twitter:image:alt" content="Young blonde woman using her smartphone" />
    </Head>
    <TrackingPortal />
  </>
);

export default TrackingPortalPage;
