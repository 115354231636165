import type { AuthenticateUserResponse } from '@tracking/data';

import { client } from './client';

type AuthenticateUserParams = {
  tos_id: string;
  email: string;
};

export const authenticateUser = async (params: AuthenticateUserParams) => {
  const { data } = await client.post<AuthenticateUserResponse>('/authenticate_user', params);

  return data;
};
