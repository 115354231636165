import Image from 'next/image';
import Flattered from 'public/flattered-logo.svg';
import Haypp from 'public/haypp-logo.svg';
import IdealOfSweden from 'public/ideal_of_sweden-logo.svg';
import Mojras from 'public/morjas-logo.svg';
import SweTNC from 'public/swe-tnc-logo.svg';
import TVINS from 'public/tvins.svg';

import { useFormatMessage } from '@tracking/i18n';

import { Text } from '$src/components';

import * as styles from './ingrid-customers.css';

export const IngridCustomers = () => {
  const formatMessage = useFormatMessage();

  return (
    <article className={styles.mainContainer}>
      <div className={styles.ingridCustomersHeader}>
        <Text textStyle={'light1628'}>{formatMessage('PORTAL.LOGOS.HEADER')}</Text>
      </div>
      <div className={styles.mainLogosWrapper}>
        <div className={styles.ingridCustomersLogo}>
          <Image src={Flattered} alt="Flattered logo" />
        </div>
        <div className={styles.ingridCustomersLogo}>
          <Image src={TVINS} alt="TVINS logo" />
        </div>
        <div className={styles.ingridCustomersLogo}>
          <Image src={IdealOfSweden} alt="IdealOfSweden logo" />
        </div>

        <div className={styles.ingridCustomersLogo}>
          <Image src={Mojras} alt="Mojras logo" />
        </div>
        <div className={styles.ingridCustomersLogo}>
          <Image src={Haypp} alt="Haypp logo" />
        </div>
        <div className={styles.ingridCustomersLogo}>
          <Image src={SweTNC} alt="SweTNC logo" />
        </div>
      </div>
    </article>
  );
};
