import type { SearchOrderStatusRequest, SearchOrderStatusResponse } from '@tracking/data';

import { client } from './client';

export const searchOrderStatus = async (requestData: SearchOrderStatusRequest) => {
  const { data } = await client.post<SearchOrderStatusResponse>(
    '/order_status.search',
    requestData
  );

  return data;
};
