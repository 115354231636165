import { useRouter } from 'next/router';
import { FC, useContext } from 'react';

import { localeToLocalName } from '@tracking/i18n';

import { ArrowRightwards, CloseButton, Text } from '$src/components';
import {
  getProposalIntl,
  langStoreContext,
  useNavigatorLanguage,
  useShowBanner,
} from '$src/modules/i18n';
import { useGetLocaleMessages } from '$src/modules/i18n/use-get-locale-messages';

import * as styles from './lang-banner.css';

export const LangBanner: FC = () => {
  const { push, asPath } = useRouter();
  const context = useContext(langStoreContext);
  const { isBannerVisible, closeBanner } = useShowBanner();
  const language = useNavigatorLanguage();
  const { localeMessages, isLoading } = useGetLocaleMessages(language);

  const formatMessage = getProposalIntl(language, localeMessages);

  if (!isBannerVisible || !context || isLoading) {
    return null;
  }

  const onContinue = () => {
    context?.dismissLangSelection();
    push(asPath, undefined, { locale: language });
  };

  return (
    <div className={styles.langBanner} data-test="language-detector-banner">
      <div className={styles.bannerHeader}>
        <Text textStyle="light1620" textColor="white">
          {formatMessage('COMMON.LANG_BANNER.TITLE')}
        </Text>
        <CloseButton fillColor="white" onClick={closeBanner} className={styles.headerCloseButton} />
      </div>
      <div className={styles.buttonsSection}>
        <button className={styles.changeLangButton} onClick={() => push('/choose-language')}>
          <Text textStyle="light1620" textColor="white">
            {localeToLocalName.get(language)}
          </Text>
          <Text textStyle="light1620" textColor="white" dataTest="language-detector-change-link">
            {formatMessage('COMMON.LANG_BANNER.CHANGE')}
          </Text>
        </button>
        <button
          className={styles.continueButton}
          onClick={onContinue}
          data-test="language-detector-continue-button"
        >
          <Text textStyle="bold1620">{formatMessage('COMMON.LANG_BANNER.CONTINUE')}</Text>
          <ArrowRightwards arrowColor="black" />
        </button>
        <CloseButton fillColor="white" onClick={closeBanner} className={styles.bannerCloseButton} />
      </div>
    </div>
  );
};
