import { QueryClient } from '@tanstack/react-query';

import { TRACKING_DATA_QUERY_KEY } from '@tracking/data';

import { getTrackingDataByToken, getTrackingDataByTosId } from './api';

export const prefetchDataByTosId = (queryClient: QueryClient, tosId: string, locale?: string) =>
  queryClient.prefetchQuery([TRACKING_DATA_QUERY_KEY], () => getTrackingDataByTosId(tosId, locale));

export const prefetchDataByToken = (queryClient: QueryClient, token: string, locale?: string) =>
  queryClient.prefetchQuery([TRACKING_DATA_QUERY_KEY], () => getTrackingDataByToken(token, locale));
