import { FC } from 'react';

import { Footer } from '$src/modules/footer';
import { IngridCustomers } from '$src/modules/tracking-portal/ingrid-customers';

import { Header } from './header';
import { LangBanner } from './lang-banner';
import { LearnMore } from './learn-more';
import { Search } from './search';
import * as styles from './tracking-portal.css';

export const TrackingPortal: FC = () => {
  return (
    <div className={styles.container}>
      <LangBanner />
      <Header />
      <Search />
      <IngridCustomers />
      <LearnMore />
      <Footer />
    </div>
  );
};
