import type { GetTrackingDataResponse } from '@tracking/data';

import { client } from './client';

type GetTrackingDataParams = {
  token?: string;
  tosId?: string;
  locale?: string;
};

export const getTrackingData = async ({ token, tosId, locale }: GetTrackingDataParams) => {
  if (token) {
    const { data } = await client.get<GetTrackingDataResponse>('/tracking_data.get', {
      params: { token, locale },
    });

    return data;
  }
  const { data } = await client.get<GetTrackingDataResponse>('/tracking_data.get', {
    params: { tos_id: tosId, locale },
  });

  return data;
};

export const getTrackingDataByTosId = async (tosId: string, locale?: string) => {
  const { data } = await client.get<GetTrackingDataResponse>('/tracking_data.get', {
    params: { tos_id: tosId, locale },
  });

  return data;
};

export const getTrackingDataByToken = async (token: string, locale?: string) => {
  const { data } = await client.get<GetTrackingDataResponse>('/tracking_data.get', {
    params: { token, locale },
  });

  return data;
};
