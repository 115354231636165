import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { LOCALE_MESSAGES_QUERY_KEY } from '@tracking/data';

import { getLocaleMessages } from '$src/modules/data/api/locale-messages';

export const useGetLocaleMessages = (userLocale?: string) => {
  const { locale } = useRouter();

  const { data: localeMessages, isLoading } = useQuery({
    queryKey: [LOCALE_MESSAGES_QUERY_KEY],
    queryFn: () => getLocaleMessages(userLocale || locale),
    staleTime: 5000,
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
  });

  return { localeMessages, isLoading };
};
