import clsx from 'clsx';
import type { FC } from 'react';

import { useAnalytics } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';
import { IngridLogo } from '@tracking/ui';

import { GoExternalLink } from '$src/components';

import * as commonStyles from './common.css';
import * as styles from './header.css';

export const Header: FC = () => {
  const formatMessage = useFormatMessage();
  const analytics = useAnalytics();
  return (
    <header className={styles.headerContainer}>
      <div className={clsx([commonStyles.content, styles.headerContent])}>
        <IngridLogo className={styles.ingridLogo} />
        <GoExternalLink
          className={styles.ingridButton}
          href={'https://ingrid.com'}
          dataTest="portal-header-ingrid-link"
          onPosthogCapture={() => analytics.capture('redirect_ingrid_com')}
        >
          {formatMessage('PORTAL.HEADER.GO_TO_INGRID')}
        </GoExternalLink>
      </div>
    </header>
  );
};
