import clsx from 'clsx';
import type { FC } from 'react';

import { useAnalytics } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { GoExternalLink, Text } from '$src/components';

import * as commonStyles from './common.css';
import * as styles from './learn-more.css';

export const LearnMore: FC = () => {
  const formatMessage = useFormatMessage();
  const analytics = useAnalytics();

  return (
    <article className={clsx([commonStyles.mainCustomersContainer, styles.mainContainerBlack])}>
      <div className={clsx([commonStyles.content, styles.mainContent])}>
        <div className={commonStyles.contentStack}>
          <h2 className={styles.contentHeaderText}>
            {formatMessage('PORTAL.INGRID_CUSTOMERS.HEADER')}
          </h2>
        </div>
        <div className={styles.contentStack}>
          <Text textStyle={'regular2028'} textColor={'white'}>
            {formatMessage('PORTAL.INGRID_CUSTOMERS.DESCRIPTION')}
          </Text>
          <GoExternalLink
            href={'https://www.ingrid.com/market-customers'}
            linkColor={'white'}
            className={styles.customersButton}
            dataTest="portal-ingrid-customers-link"
            onPosthogCapture={() => analytics.capture('redirect_brands_using_ingrid')}
          >
            {formatMessage('PORTAL.INGRID_CUSTOMERS.LINK')}
          </GoExternalLink>
        </div>
      </div>
    </article>
  );
};
