import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'zod';

import { type SearchOrderStatusRequest, useAnalytics } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';
import { Button, TextInputWithMask } from '@tracking/ui';

import { Text } from '$src/components';
import { searchOrderStatus } from '$src/modules/data';
import { writeStorage } from '$src/modules/storage';

import * as commonStyles from './common.css';
import * as styles from './search.css';

type SearchFormFields = {
  identifier: string;
  contact: string;
};

export const Search: FC = () => {
  const formatMessage = useFormatMessage();
  const { query, locale } = useRouter();

  const prefillId = query.prefillIdentifier as string | undefined;
  const prefillContact = query.prefillContact as string | undefined;
  const analytics = useAnalytics();
  const { mutate, isError, isLoading } = useMutation(
    async (requestData: SearchOrderStatusRequest) => {
      const data = await searchOrderStatus(requestData);
      // this is needed to prevent ugly nextjs error in case BE does not return 404 error code on empty search result
      if (!data.orders || !data.orders.length) {
        throw 'error: empty data from order_status.search';
      }
      return data;
    },
    {
      onError: () => {
        analytics.capture('error_while_searching_for_delivery');
      },
      onSuccess: (data, requestData) => {
        const order = data.orders?.[0];
        const token = order?.access_token;

        writeStorage({
          token,
          actualId: requestData.identifier,
          actualContact: requestData.contact,
          prefillId,
          prefillContact,
        });

        // TODO: Can't use router's push method as the side effect is the font-family for html element is being replaced.
        // Check for conflicts/issues the font-family declarations for the html object. There are some occurrences in sub-components.
        globalThis.window.location.href = `/${locale}/${token}`;
      },
    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm<SearchFormFields>({
    mode: 'onTouched',
    defaultValues: {
      identifier: prefillId || '',
      contact: prefillContact || '',
    },
    resolver: zodResolver(
      object({
        identifier: string().min(1, formatMessage('PORTAL.SEARCH.IDENTIFIER_REQUIRED')),
        contact: string()
          .min(1, formatMessage('PORTAL.SEARCH.CONTACT_REQUIRED'))
          .email(formatMessage('AUTH.VALID_EMAIL_ADDRESS')),
      })
    ),
  });

  useEffect(() => {
    const actualId = globalThis.sessionStorage?.getItem('GO_BACK_ACTUAL_ID');
    const actualContact = globalThis.sessionStorage?.getItem('GO_BACK_ACTUAL_CONTACT');

    reset({
      identifier: actualId || prefillId || '',
      contact: actualContact || prefillContact || '',
    });
  }, [prefillContact, prefillId, reset]);

  const onSubmit = handleSubmit(({ contact, identifier }) => {
    analytics.capture('search_for_delivery');
    mutate({ identifier: identifier.trim(), contact: contact.trim() });
  });

  const { onBlur: onIdentifierBlur, ...identifierRegisterProps } = register('identifier');
  const { onBlur: onContactBlur, ...contactRegisterProps } = register('contact');

  return (
    <article className={commonStyles.mainContainer}>
      <div className={clsx([commonStyles.content, commonStyles.mainTrackDeliveryContent])}>
        <div className={commonStyles.contentStack}>
          <div>
            <h1 className={styles.contentHeaderText}>{formatMessage('PORTAL.SEARCH.HEADER')}</h1>
          </div>
          <div className={styles.contentHeaderDescription}>
            <Text textStyle={'light2026'}>{formatMessage('PORTAL.SEARCH.DESCRIPTION')}</Text>
          </div>
        </div>
        <form onSubmit={onSubmit} className={styles.searchFormContainer}>
          <div className={styles.errorForm}>
            {isError && (
              <Text
                textStyle={'light1620'}
                dataTest="error-package-not-found"
                textColor={'lightRed'}
              >
                {formatMessage('PORTAL.SEARCH.UNFOUND_ERROR')}
              </Text>
            )}
          </div>
          <div className={styles.searchInputs}>
            <TextInputWithMask
              {...identifierRegisterProps}
              onBlur={e => {
                const identifierValue = getValues().identifier;
                if (identifierValue) {
                  analytics.capture('identifier_input', {
                    identifier: identifierValue,
                  });
                }
                onIdentifierBlur(e);
              }}
              className={styles.searchInput}
              label={formatMessage('PORTAL.SEARCH.IDENTIFIER_PLACEHOLDER')}
              id="identifier"
              name="identifier"
              error={errors.identifier}
              dataTest="search-identifier-input"
              value={watch('identifier')}
            />
            <TextInputWithMask
              {...contactRegisterProps}
              onBlur={e => {
                const contactValue = getValues().contact;
                if (contactValue) {
                  analytics.capture('contact_input', {
                    contact: contactValue,
                  });
                }
                onContactBlur(e);
              }}
              className={styles.searchInput}
              label={formatMessage('PORTAL.SEARCH.CONTACT_PLACEHOLDER')}
              id="contact"
              name="contact"
              error={errors.contact}
              dataTest="search-contact-input"
              value={watch('contact')}
            />
          </div>
          <Button isLoading={isLoading} dataTest="portal-search-button">
            <Text textColor="white" textStyle="regular1420">
              {formatMessage('PORTAL.SEARCH.SEARCH')}
            </Text>
          </Button>
        </form>
      </div>
    </article>
  );
};
