export type GoBackStorage = {
  token?: string;
  actualId?: string;
  actualContact?: string;
  prefillId?: string;
  prefillContact?: string;
};

const TOKEN_KEY = 'GO_BACK_TOKEN';
const ACTUAL_ID_KEY = 'GO_BACK_ACTUAL_ID';
const ACTUAL_CONTACT_KEY = 'GO_BACK_ACTUAL_CONTACT';
const PREFILL_ID_KEY = 'GO_BACK_PREFILL_ID';
const PREFILL_CONTACT_KEY = 'GO_BACK_PREFILL_CONTACT';

export const writeStorage = (values: GoBackStorage) => {
  values.token && globalThis.sessionStorage?.setItem(TOKEN_KEY, values.token);
  values.actualId && globalThis.sessionStorage?.setItem(ACTUAL_ID_KEY, values.actualId);
  values.actualContact &&
    globalThis.sessionStorage?.setItem(ACTUAL_CONTACT_KEY, values.actualContact);
  values.prefillId && globalThis.sessionStorage?.setItem(PREFILL_ID_KEY, values.prefillId);
  values.prefillContact &&
    globalThis.sessionStorage?.setItem(PREFILL_CONTACT_KEY, values.prefillContact);
};

export const readStorage = (): GoBackStorage => {
  return {
    token: globalThis.sessionStorage?.getItem(TOKEN_KEY) ?? undefined,
    actualId: globalThis.sessionStorage?.getItem(ACTUAL_ID_KEY) ?? undefined,
    actualContact: globalThis.sessionStorage?.getItem(ACTUAL_CONTACT_KEY) ?? undefined,
    prefillId: globalThis.sessionStorage?.getItem(PREFILL_ID_KEY) ?? undefined,
    prefillContact: globalThis.sessionStorage?.getItem(PREFILL_CONTACT_KEY) ?? undefined,
  };
};
