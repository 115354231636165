import type { GetPageBrandingResponse } from '@tracking/data';

import { client } from './client';

export const getBrandingCss = async (token?: string, tosId?: string) => {
  if (token) {
    const { data } = await client.get<GetPageBrandingResponse>('/branding/page', {
      params: { token },
    });

    return data?.branding;
  }

  const { data } = await client.get<GetPageBrandingResponse>('/branding/page', {
    params: { tos_id: tosId },
  });

  return data?.branding;
};
